export default {
  en: {
    attentionNetwork: `Attention!<br>
    Check the entered data.<br>
    Matching wallet address and selected network (ERC-20 / TRC-20).`,
    oneTokenPrice: 'FIX One Token Price',
    withdrawToUSDT: 'Withdraw in USDT',
    usdtTotal: 'USDT Total',
    usdtTotalPlaceholder: 'How much USDT you want to withdraw?',
    FixTotal: 'FIX Token',
    FixTotalPlaceholder: 'How much FIX you want to withdraw?',
    usdtWallet: 'USDT Wallet address',
    usdtWalletPlaceholder: 'Your USDT Wallet Address',
    usdtGetTotal: 'Total USDT you will get',
    usdtGetTotalPlaceholder: 'Total USDT you will get',
    totalWithCommission: 'AMMOUNT WITH BLOCKCHAIN COMMISSION FOR TRANSACTION',
    totalWithDexCommission: 'AMMOUNT WITH 2% COMMISSION FOR TRANSACTION',
    exchangeUSDToFIX: 'Exchange USD to FIX One Token',
    exchangeUSDToDEX: 'Exchange USD to FIX One Token',
    usdTotal: 'USD Total',
    usdTotalPlaceholder: 'How much USD you want to exchange?',
    totalFIXOneWillGet: 'Total FIX One you will get',
    totalDexWillGet: 'Total DEX you will get',
    totalFIXOneWillGetPlaceholder: 'FIX One Token Total',
    totalDexWillGetPlaceholder: 'DEX Token Total',
    totalWithUSDCommission: 'TOTAL WITH 1% COMMISSION',
    exchange: 'Exchange',
    confirmData: 'Please confirm your data',
    confirm: 'Confirm',
    partnershipTransactions: 'Partnership Account Transactions',
    partnershipDexnetTransactions: 'Partnership DEXNET Account Transactions',
    hoursProceed: 'Your withdrawal request will be processed in accordance with the rules within 24 hours.',
    partnerName: 'Partner Name',
    userLevel: 'User level',
    verificationCode: 'Enter your verification code from Email',
    enterCode: 'Enter code',
    resendCode: 'Resend code',
    submitWithdraws: 'Submit Withdraws',
    submitInstruction: 'Enter your Transaction HASH  You can find your transaction ID in your wallet (Trust Wallet, Metamask and etc)',
    availableBalance: 'Available balance',
    choiceNetwork: 'Select Network',
    partnership: 'Partnership',
    exchangeVideo: 'https://www.youtube.com/embed/vdzMx21XiTU',
  },
  ru: {
    attentionNetwork: `Внимание!<br>
    Проверьте введенные данные.<br>
    Соответствие адреса кошелька и выбранная сеть (ERC-20/TRC-20).`,
    oneTokenPrice: 'FIX One Token Price',
    withdrawToUSDT: 'Вывод в USDT',
    usdtTotal: 'USDT Всего',
    usdtTotalPlaceholder: 'Сколько USDT Вы хотите вывести?',
    FixTotal: 'FIX Token',
    FixTotalPlaceholder: 'Сколько FIX вы хотите вывести?',
    usdtWallet: 'Адрес USDT кошелька',
    usdtWalletPlaceholder: 'Ваш адрес USDT Кошелька',
    usdtGetTotal: 'Итого USDT Вы получите',
    usdtGetTotalPlaceholder: 'Итого USDT Вы получите',
    totalWithCommission: 'ИТОГО С УЧЕТОМ КОМИССИИ БЛОКЧЕЙНА ЗА ТРАНЗАКЦИЮ ',
    totalWithDexCommission: 'ИТОГО С УЧЕТОМ КОМИССИИ 2% ЗА ТРАНЗАКЦИЮ ',
    exchangeUSDToFIX: 'Обменять USD на FIX One Токен',
    exchangeUSDToDEX: 'Обменять USD на FIX One Токен',
    usdTotal: 'USD Всего',
    usdTotalPlaceholder: 'Сколько USD Вы хотите обменять?',
    totalFIXOneWillGet: 'Всего FIX One Токенов Вы получите',
    totalDexWillGet: 'Всего FIX One Токенов Вы получите',
    totalFIXOneWillGetPlaceholder: 'FIX One Токенов Всего',
    totalDexWillGetPlaceholder: 'FIX One Токенов Всего',
    totalWithUSDCommission: 'ИТОГО С 1% КОМИССИЕЙ',
    exchange: 'Обменять',
    confirmData: 'Пожалуйста, Подтвердите Ваши данные!',
    confirm: 'Подтвердить',
    partnershipTransactions: 'Транзакции Партнёрского счёта',
    partnershipDexnetTransactions: 'Транзакции Партнёрского DEXNET счёта',
    hoursProceed: 'Ваш запрос на вывод средств будет обрабатываться в соответствии с правилами в течение 24 часов',
    partnerName: 'Имя партнера',
    userLevel: 'Уровень',
    verificationCode: 'Введите проверочный код из электронной почты',
    enterCode: 'Введите код',
    resendCode: 'Отправить код повторно',
    submitWithdraws: 'Подтверждение вывода',
    submitInstruction: ' Введите HASH вашей транзакции, Вы можете найти идентификатор транзакции в вашем кошельке (Trust Wallet, Metamask и т.д.)',
    availableBalance: 'Доступный баланс',
    choiceNetwork: 'Выберите сеть',
    partnership: 'Партнерский',
    exchangeVideo: 'https://www.youtube.com/embed/O_TP1W1L26c',
  },
  es: {
    attentionNetwork: `Atención!<br>
    Compruebe los datos introducidos.<br>
    Dirección de billetera coincidente y red seleccionada (ERC-20 / TRC-20).`,
    oneTokenPrice: 'FIX One Token Price',
    withdrawToUSDT: 'Retirar a USDT',
    usdtTotal: 'USDT Total',
    usdtTotalPlaceholder: 'Cuánto USDT desea retirar?',
    FixTotal: 'FIX Token',
    FixTotalPlaceholder: 'Cuánto quieres retirar de FIX?',
    usdtWallet: 'Billetera USDT',
    usdtWalletPlaceholder: 'Dirección de Su Billetera USDT',
    usdtGetTotal: 'USDT Total que obtendrá',
    usdtGetTotalPlaceholder: 'USDT Total que obtendrá',
    totalWithCommission: 'TOTAL CON COMISIÓN BLOCKCHAIN ​​POR TRANSACCIÓN',
    totalWithDexCommission: 'TOTAL CON COMISIÓN 2% ​​POR TRANSACCIÓN',
    exchangeUSDToFIX: 'Cambiar USD a FIX One Token',
    exchangeUSDToDEX: 'Cambiar USD a FIX One Token',
    usdTotal: 'USD Total',
    usdTotalPlaceholder: 'Cuánto USD desea cambiar?',
    totalFIXOneWillGet: 'Total FIX One que obtendrás',
    totalFIXOneWillGetPlaceholder: 'FIX One Token Total',
    totalDexOneWillGetPlaceholder: 'FIX One Token Total',
    totalWithUSDCommission: 'TOTAL CON 1% DE COMISIÓN',
    exchange: 'Intercambio',
    confirmData: 'Confirma sus Datos',
    confirm: 'Confirmar',
    partnershipTransactions: 'Transacciones de Cuentas de Asociación',
    partnershipDexnetTransactions: 'Transacciones de Cuentas de Asociación',
    hoursProceed: 'Su solicitud de retiro se procesará de acuerdo con las reglas dentro de las 24 horas.',
    partnerName: 'Nombre del Socio',
    userLevel: 'Nivel de Usuario',
    verificationCode: 'Introduzca el código de verificación del correo electrónico',
    enterCode: 'Introduzca el código',
    resendCode: 'reenviar código',
    submitWithdraws: 'Presentar retiradas',
    submitInstruction: 'Introduzca su HASH de transacción Puede encontrar su ID de transacción en su cartera (Trust Wallet, Metamask y etc)',
    availableBalance: 'Saldo disponible',
    choiceNetwork: 'Seleccione la red',
    partnership: 'Cuenta de Asociación',
    exchangeVideo: 'https://www.youtube.com/embed/USQhZAdlTZo',
  }
}
