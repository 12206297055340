<template>
  <div class="type_col flex flex-col items-start">
    <h4 class="type_col__name text-left text-xs sm:text-base"
        :class="[isDexType(props.type) ? 'text-[#00FFF0]' : ''] "
    >
      {{ t(typeName[props.type]) }} {{ props.type === 11 ? 'FIX GOLD' : '' }}
    </h4>
    <small class="dark:text-[#8d8d8d] text-xs sm:text-base">{{
      props.date ? props.date.split(' ')[0].split('-').reverse().join('.') : '-'
    }}</small>
    <p v-if="props.wallet" class="type_col__wallet text-sm bg-[#ebf1f6] dark:bg-[#272727]">{{props.wallet}}</p>

  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const props = defineProps({
  type: Number,
  date: String,
  wallet: {
    type: String,
    required: false,
    default: null
  },
  billType: {
    type: Number,
    required: false,
    default: () => 1
  }
})
const { t } = useI18n()
const typeName = ref({
  11: 'depositTransaction',
  0: 'depositTransaction',
  1: 'withdrawType',
  2: 'exchangeType',
  3: 'directBonusType',
  4: 'globalPoolGoldType',
  5: 'globalPoolDiamondType',
  6: 'weeklyBonusType',
  7: 'depositFixBonusType',
  8: 'depositUsdBonusType',
  9: 'withdrawFixBonusType',
  10: 'withdrawUsdBonusType',
  12: 'autoExchangeType',
  13: 'withdrawFixGoldType',
  14: 'exchangeFixGoldType',
  15: 'depositCryptohouseType',
  16: 'exchangeFixOneType',
  17: 'DEXNET API KEY',
  18: 'DEXNET API KEY usdt',
  19: 'DEXNET API KEY partner',
  20: 'DEXNET Direct Bonus',
  21: 'DEXNET TEAM BONUS',
  22: 'DEXNET MARAFON BONUS',
  23: 'DEX POOL BONUS',
  24: 'DEXNET DEPOSIT USDT',
  25: 'DEXNET VALIDATOR KEY',
  26: 'VALIDATOR DIRECT BONUS USDT',
  27: 'VALIDATOR DIRECT BONUS DEXNET',
  28: 'DEPOSIT FIX TWO',
  29: 'WITHDRAW FIX TWO'
})

const isDexType = computed(() => {
  return type => {
    return [17, 18, 19, 20, 21, 22, 23, 24].includes(type)
  }
})
</script>

<style scoped>
.type_col__name {
  word-wrap: break-word;
  max-width: 100%;
}

.type_col {
  max-width: 100%;
  z-index: 1;
  overflow: hidden;
  
  &:hover{
    overflow: visible;
  }
}

@media (max-width: 400px) {
  .type_col__name {
    font-size: 10px;
  }
}

.type_col__wallet{
  display: block;
  background: #000;
}

@media(max-width: 470px) {
  .type_col__wallet{
    font-size: 10px;
  }
}
</style>
