<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.95 36.66" class="w-5 h-5">
    <g id="Слой_2" data-name="Слой 2">
      <g id="ProfixOne_Token_2_копия_" data-name="ProfixOne Token 2 (копия)">
        <g id="Слой_26_копия2_" data-name="Слой 26 (копия2)">
          <path class="cls-1" d="M29.75,27.08A14.4,14.4,0,1,1,29,8.62a7.2,7.2,0,0,1,1.54-.17h.32a7.42,7.42,0,0,1,2.37-.4h.32a18.32,18.32,0,1,0,.37,20H33.5A7.58,7.58,0,0,1,29.75,27.08Z"/>
          <rect class="fill-black dark:fill-white" x="14.86" y="21.74" width="4.66" height="6.31"/>
          <rect class="fill-black dark:fill-white" x="14.86" y="16.58" width="4.66" height="4.12"/>
          <path class="cls-1" d="M33.74,8.07,27,17.92,34,28.05h-5l-4.36-6.53-4.38,6.53h-5l7-10.13L15.45,8.07h5l4.17,6.2,4.15-6.2Z"/>
          <path class="fill-black dark:fill-white" d="M6.38,28.05v-20H20.47v4.14h-10v4.37H14v4.14H10.5v7.33Z"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1{
  fill:#dbb96b;
}
</style>
