import axios from 'axios'
import { url } from '@/main'

export default {
  state: {
    fixOneChangeRate: {
      price: 0,
      days: null
    },
    fixGoldChangeRate: {
      price: 0,
      days: null
    },
    fixTwoChangeRate: {
      price: 0,
      days: null
    },
    daysGrowth: 3,
    walletBlockchains: [
      {
        id: 1, name: 'TRC20commission', value: 1, unavailable: false
      },
      {
        id: 2, name: 'ERC20commission', value: 15, unavailable: false
      },
      {
        id: 3, name: 'BEP20commission', value: 1, unavailable: false
      },
    ],
    walletDexBlockchains: [
      {
        id: 1, name: 'TRC20DexCommission', value: 2, unavailable: false
      },
      {
        id: 2, name: 'ERC20DexCommission', value: 2, unavailable: false
      },
      {
        id: 3, name: 'BEP20DexCommission', value: 2, unavailable: false
      },
    ]
  },
  getters: {
    getFixOneChangeRate(state) {
      return state.fixOneChangeRate
    },
    getFixGoldChangeRate(state) {
      return state.fixGoldChangeRate
    },
    getFixTwoChangeRate(state) {
      return state.fixTwoChangeRate
    },
    getWalletBlockchains(state) {
      return state.walletBlockchains
    },
    getDexWalletBlockchains(state) {
      return state.walletDexBlockchains
    }
  },
  mutations: {
    updPrices(state, payload = {}) {
      state.fixOneChangeRate = { ...payload.one }
      state.fixGoldChangeRate = { ...payload.gold }
      state.fixTwoChangeRate = { ...payload.two}
    }
  },
  actions: {
    async getPrice(context) {
      await axios.get(`${url}/api/common/tokens`).then((response) => {
        context.commit('updPrices', response.data)
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem('tokenAuth')
          // document.location.href = document.location.origin
        }
      })
    }
  }
}
