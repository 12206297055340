export default {
  en: {
    TRC20commission: 'TRC20 - 1$ commission',
    ERC20commission: 'ERC20 - 15$ commission',
    BEP20commission: 'BEP20 - 1$ commission',
    TRC20DexCommission: 'TRC20 - 2% commission',
    ERC20DexCommission: 'ERC20 - 2% commission',
    BEP20DexCommission: 'BEP20 - 2% commission',
    networkCommissions: '<span>commission for TRC20 is 1$</span> <br> <span>commission for ERC20 is 15$</span>',
    start: "let's start",
    loading: '...loading',
    withdrawTransaction: 'Withdraw Transactions',
    submit: 'submit',
    success: 'Successfully send!',
    successWait: 'Wait for administrator confirmation',
    successTransaction: 'Successfully',
    codeResend: 'Code was sent in the mail',
    buyHouse: 'buy',
    globalCruise: 'PROMO PERIOD'
  },
  ru: {
    TRC20commission: 'TRC20 - 1$ комиссия',
    ERC20commission: 'ERC20 - 15$ комиссия',
    BEP20commission: 'BEP20 - 2% комиссия',
    TRC20DexCommission: 'TRC20 - 2% комиссия',
    ERC20DexCommission: 'ERC20 - 2% комиссия',
    BEP20DexCommission: 'BEP20 - 2% комиссия',
    networkCommissions: '<span>комиссия для TRC20 - 1$</span> <br> <span>комиссия для ERC20 - 15$</span>',
    start: 'Начнём',
    loading: '...загрузка',
    withdrawTransaction: 'Withdraw Transactions',
    submit: 'Подтвердить',
    success: 'Успешно отправлено!',
    successWait: 'Ожидайте подтверждения администратора',
    successTransaction: 'Успешно!',
    codeResend: 'код был отправлен на почту',
    buyHouse: 'купить',
    globalCruise: 'Период проведения промо'
  },
  es: {
    TRC20commission: 'TRC20 - 1$ comisión',
    ERC20commission: 'ERC20 - 15$ comisión',
    BEP20commission: 'BEP20 - 2% comisión',
    TRC20DexCommission: 'TRC20 - 2% comisión',
    ERC20DexCommission: 'ERC20 - 2% comisión',
    BEP20DexCommission: 'BEP20 - 2% comisión',
    networkCommissions: '<span>la comisión por TRC20 es de  1$</span> <br> <span>la comisión por ERC20 es de  15$</span>',
    start: 'Comenzamos',
    loading: '...cargando',
    withdrawTransaction: 'Withdraw Transactions',
    submit: 'enviar',
    success: '¡Enviado con éxito!',
    successWait: 'Esperar la confirmación del administrador',
    successTransaction: 'Con éxito',
    codeResend: 'el código ha sido enviado a la oficina de correos',
    buyHouse: 'comprar',
    globalCruise: 'PERIODO DE PROMOCIÓN'
  }
}
